//const normalizePath = (base, path) => {  return base.replace(/\/$/, "") + "/" + path.replace(/^\//, "");};

  const host = 'https://servicontacto.com/';
//const host = 'http://localhost/';

const types = {
  authLogin: "auth - login",
  authLogout: "auth - logout",
  paises: "paises",
  ciudades: "ciudades",
  provincias: "provincias",
  distritos: "distritos",
  distritosDpto: "distritosDpto",
  ubicacion: "ubicacion",
  contactosUser: "contactosUser",
  contactos: "contactos",
  contacto: "contacto",
  especialidad: "especialidad",
  categorias: "categorias",
  categoria: "categoria",
  zona: "zona",
  alertas: "alertas",
  formulario: "formulario",
  menu: "menu",
  menuCel: "menuCel",  
  visita: "visita",
  cuenta: "cuenta",
  control: "control",
};

const initialStore = {
  api: host+"supercontacto/portal.php",
  ruta: host+"dataContacto/",
  rutaWeb: "",
  apiFile: host+"supercontacto/uploadAxiosFoto.php",
  apiLogin: host+"supercontacto/login.php",
  home: "/",
  logo: "assets/images/Servicontacto.png",
  logom: "assets/images/Sc.png",  
  testMail:/^\w+([-.]?\w+)*@\w+([-]?\w+)*(\.\w{2,4})+$/,
  alertas: "",
  cuenta:0,
  control:{caja:false, opciones:false, buscar:false, zonas:false},
  esEscritorio: window.innerWidth > 800 ,
  ubicacion: {...(JSON.parse(localStorage.getItem("ubicacion"))), ViaIP:false},
  contactosUser: [],
  contactos: [{ Contacto_ID: "0" }],
  contacto: { editar: "" },
  formulario: { editar: "" },
  especialidad: [],
  categorias: [],
  user: { login:false}, 
  categoria: { parametro: "Categoría/sub-categoría/contacto" },
  paises: JSON.parse(localStorage.getItem("paises")) || [],
  ciudades: JSON.parse(localStorage.getItem("ciudades")) || [],
  provincias: JSON.parse(localStorage.getItem("provincias")) || [],
  distritos: JSON.parse(localStorage.getItem("distritos")) || [],
  distritosDpto: [{}],
  zona: { data:{ Pais_ID: "89" }},
  menu: [
    {  label: 'Profesionales', icon: 'fas fa-user-tie', top: -120, items: [ ] },
    {  label: 'Técnicos y oficios', icon: 'fas fa-user-nurse', top: -220,  items: [ ]  },
    {  label: 'Vendedores', icon: 'fas fa-cash-register', top: -320,  items: [ ]  },
    {  label: 'Productores', icon: 'fas fa-seedling', top: -420,  items: [ ]  },
    {  label: 'Negocios', icon: 'fas fa-briefcase', top: -520,  items: [ ]  },
    {  label: 'Segunda mano', icon: 'pi pi-fw pi-users', top: -620,  items: [ ]  },
    {  label: 'Alquiler', icon: 'fas fa-car-alt', top: -720,   items: [ ]  }
    ],
  menuCel: [
    {  label: 'Profesionales', icon: 'fas fa-user-tie', top: -120, items: [ ] },
    {  label: 'Técnicos y oficios', icon: 'fas fa-user-nurse', top: -220,  items: [ ]  },
    {  label: 'Vendedores', icon: 'fas fa-cash-register', top: -320,  items: [ ]  },
    {  label: 'Productores', icon: 'fas fa-seedling', top: -420,  items: [ ]  },
    {  label: 'Negocios', icon: 'fas fa-briefcase', top: -520,  items: [ ]  },
    {  label: 'Segunda mano', icon: 'pi pi-fw pi-users', top: -620,  items: [ ]  },
    {  label: 'Alquiler', icon: 'fas fa-car-alt', top: -720,   items: [ ]  }
    ],
  img: {
    fotoF: "assets/images/fotoF.png",
    fotoM: "assets/images/fotoM.png",
    logoC: "assets/images/LogoC.png",
    logoB: "assets/images/LogoB.png",
    logoT: "assets/images/LogoT.png",
    logoQ: "assets/images/LogoQ.png",	
    fotoI: "assets/images/start.png",
    logo:  "assets/images/LogoC.png",
    banner: "assets/images/banner.png",
    staff: "assets/images/bannerU.jpg",
    perfil_M: "assets/images/perfil_M.png",
    perfil_F: "assets/images/perfil_F.png",
    flag: "assets/images/flag_placeholder.png",
    flagEr: "assets/images/Peru.png",
    espectro: "assets/images/paleta.png",
    icono: "assets/images/+mapa.png",
	calendar: "assets/images/calendar.svg",
	tiktok: "assets/images/tiktok.svg",
	instagram: "assets/images/instagram.svg",	
	gmaps: "assets/images/gmaps.png", 
    loader: "assets/images/pre-loader.gif",
    portada: "assets/images/portada-3-990-400.png",
    portada1: "assets/images/portada-4-990-400.png",
	cupon: "assets/images/sc_card.png",
  },
  fecha:"HOY - " + new Date().toLocaleDateString("es-PE", { weekday: "short", day: "numeric" }).toUpperCase(),
  fechal:"HOY - " + new Date().toLocaleDateString("es-PE", { weekday: "long", day: "numeric" }).toUpperCase(),
  monedas: [
    { label: "soles", value: "1", simbolo: "S/." },
    { label: "dólares", value: "2", simbolo: "US $" },
    { label: "euros", value: "3", simbolo: "€" },
    { label: "", value: "0", simbolo: "" },
  ],
  horarioConfig: [
	{value:"0", label1:"Según disponibilidad", label:"De acuerdo a disponibilidad de citas", solo:"Ver calendario de citas"},
	{value:"1", label1:"Lunes - jueves",  label:"Lunes - jueves + viernes parcial", solo:"viernes"},
	{value:"2", label1:"Lunes - viernes", label:"Lunes - viernes + sábado parcial", solo:"sábado"},	
	{value:"3", label1:"Lunes - sábado",  label:"Lunes - sábado + domingo parcial", solo:"domingo"},	
	{value:"4", label1:"Lunes - viernes", label:"Lunes - viernes", solo:""},	
	{value:"5", label1:"Lunes - sábado",  label:"Lunes - sábado", solo:""},		
	{value:"6", label1:"Lunes - domingo", label:"Lunes - domingo", solo:""},	
  ],
  disponibilidad: [
	{value:'0', label:'No publica información', uso:'', usoM:''},
	{value:'1', label:'Indica disponibilidad Hoy', uso:'Citas disponibles ', usoM:'Turnos libres '},
	{value:'2', label:'No disponible hoy',  uso:'Sin citas disponibles ', usoM:'No hay citas '},
	{value:'3', label:'Indica no atenderá hasta:',  uso:'Sin citas disponibles ', usoM:'Sin atención '},
	{value:'4', label:'Ocupado en un caso hasta:',  uso:'Trabajando a tiempo completo ', usoM:'Ocupado '},
	{value:'5', label:'De vacaciones hasta:', uso:'Vacaciones ', usoM:'Vacaciones '}],
  score: {
    alcance: "Conformidad con el servicio o la venta",
    comunica: "Facilidad de comunicación",
    tiempo: "Tiempo de espera",
    costo: "Costo razonable",  
  }
};

const storeReducer = (state, action) => {
  switch (action?.type) {
    case types.authLogout:
      return { ...state, user: action.payload };
    case types.authLogin:
      return { ...state, user: action.payload };
    case types.paises:
      return { ...state, paises: action.payload };
    case types.ciudades:
      return { ...state, ciudades: action.payload };
    case types.provincias:
      return { ...state, provincias: action.payload };
    case types.distritos:
      return { ...state, distritos: action.payload };
    case types.distritosDpto:
      return { ...state, distritosDpto: action.payload };
    case types.contactosUser:
      return { ...state, contactosUser: action.payload };
    case types.contactos:
      return { ...state, contactos: action.payload };
    case types.contacto:
      return { ...state, contacto: action.payload };
    case types.especialidad:
      return { ...state, especialidad: action.payload };
    case types.categorias:
      return { ...state, categorias: action.payload };
    case types.categoria:
      return { ...state, categoria: action.payload };
    case types.zona:
      return { ...state, zona: action.payload };
    case types.ubicacion:
      return { ...state, ubicacion: action.payload };
    case types.formulario:
      return { ...state, formulario: action.payload };
    case types.alertas:
      return { ...state, alertas: action.payload };
    case types.menu:
      return { ...state, menu: action.payload };
    case types.menuCel:
      return { ...state, menuCel: action.payload };
    case types.visita:
      return { ...state, visita: action.payload };
    case types.cuenta:
      return { ...state, cuenta: action.payload };
    case types.control:
      return { ...state, control: action.payload };	  
    default:
	console.warn(`Acción desconocida: ${action?.type}`);
      return state;
  }
};

export { initialStore, types };
export default storeReducer;
