import React from 'react';
import styled, { keyframes } from 'styled-components';

// Animación para el componente grande (Barras)
const loadAnimation = keyframes`
  0%, 100%, 80% {
    opacity: 0.75;
    box-shadow: 0 0 #fecc00;
    height: 2rem;
  }
  40% {
    opacity: 1;
    box-shadow: 0 -0.5rem #fecc00;
    height: 2.5rem;
  }
`;

// Estilos para el componente grande (Barras)
const LoaderIconContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoaderBars = styled.div`
  background: #fecc00;
  animation: ${loadAnimation} 0.8s infinite ease-in-out;
  width: 1.1rem;
  height: 2rem;
  text-indent: -9999em;
  margin: 0 1rem;
  position: relative;
  font-size: 11px;
  animation-delay: 0.16s;

  &:before,
  &:after {
    position: absolute;
    top: 0;
    content: "";
    background: #fecc00;
    animation: ${loadAnimation} 0.8s infinite ease-in-out;
    width: 1.1rem;
    height: 2rem;
  }

  &:before {
    left: -2rem;
  }

  &:after {
    left: 2rem;
    animation-delay: 0.32s;
  }
`;

const Barras = () => {
  return (
    <LoaderIconContainer id="initialLoadingContainer">
      <Loader className="is-active">
        <LoaderBars />
      </Loader>
    </LoaderIconContainer>
  );
};

// Animación para el componente pequeño (BarrasSmallHorizontal)
const loadAnimationHorizontal = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

// Estilos para el componente pequeño (BarrasSmallHorizontal)
const LoaderBarHorizontal = styled.div`
  background: #fecc00;
  width: 20%;
  height: 1rem;
  margin: 0 2px;
  animation: ${loadAnimationHorizontal} 2s infinite linear;
`;

const LoaderContainerHorizontal = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
`;

const BarrasSmall = () => {
  return (
    <LoaderContainerHorizontal>
      <LoaderBarHorizontal />
      <LoaderBarHorizontal style={{ animationDelay: '0.2s' }} />
      <LoaderBarHorizontal style={{ animationDelay: '0.4s' }} />
      <LoaderBarHorizontal style={{ animationDelay: '0.6s' }} />
      <LoaderBarHorizontal style={{ animationDelay: '0.8s' }} />
    </LoaderContainerHorizontal>
  );
};


export { Barras, BarrasSmall };