import axios from "axios";
import React, { useContext, useState, useMemo, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Row } from "primereact/row";
import { ColumnGroup } from "primereact/columngroup";
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from "primereact/button";
import { StoreContext } from "./store/StoreProvider";
import { Barras, BarrasSmall } from './components/loaders/Barras';
import { types } from "./store/StoreReducer.js";
import { LogForm } from "./Login.js";
import { InfoData } from "./InfoData";


export const Agenda = (props) => {
  const {store, dispatch} = useContext(StoreContext);
  const { user, api, ruta } = store;
  const [usuario, setUsuario] = useState("");  
  const [barras, setBarras] = useState(false);
  const [visible, setVisible] = useState(false);
  const [eventoTitulo, setEventoTitulo] = useState("");
  const [fecha, setFecha] = useState(new Date(Date.now() - 86400000));
  const [fecha1, setFecha1] = useState(new Date(Date.now() - 86400000));
  const [checked, setChecked] = useState(false);
  const [hasEnd, setHasEnd] = useState(false);
  const [modoBoton, setModoBoton] = useState("");
  const [Color, setColor] = useState("#fecc00");
  const [textColor, setTextColor] = useState("#006aa7");
  const [id, setId] = useState(""); // id de evento
  const [agenda, setAgenda] = useState([]);
//  const [ventana, setVentana] = useState([]);
  const [events, setEvents] = useState([]);
  const [eventoDescripcion, setEventoDescripcion] = useState(""); 
  const hoy = new Date().valueOf();
  const lunes = new Date().setDate(new Date().getDate() - (new Date().getDay() || 7) + 1);
  const [priDiaMes, setPriDiaMes] = useState(lunes);
  const { contacto = [] } = props || {};
  const rango_citas = contacto?.rango_citas ?? [{ dia: "Domingo" },{ dia: "Lunes" },{ dia: "Martes" },{ dia: "Miércoles" },{ dia: "Jueves" },{ dia: "Viernes" }, { dia: "Sábado" },];
  const [edit, setEdit] = useState(false); // Nuevo estado para modo edición
  const [tempDisponibilidad, setTempDisponibilidad] = useState(rango_citas); // Rangos temporales
  const [editStart, setEditStart] = useState(null); // Almacena el inicio del rango en modo edición
  const admin = (user.Usu_ID === contacto.creador);
  const [eventoActual, setEventoActual] = useState(null);
  const [hoja, setHoja] = useState(false);  
	console.log(admin, user.Usu_ID, contacto.creador)

  // Generar franjas horarias disponibles
  const turnos = useMemo(() => {
    const duracion = 30; // Turnos de 30 minutos
    const result = [];
    const minInicio =  parseInt(props.contacto?.inicio) < parseInt(props.contacto?.inicio1) ? parseInt(props.contacto?.inicio) : parseInt(props.contacto?.inicio1); // 00:00
    const maxFin = parseInt(props.contacto?.fin) > parseInt(props.contacto?.fin1) ? parseInt(props.contacto?.fin) : parseInt(props.contacto?.fin1); // 24:00
    for (let i = minInicio; i < maxFin; i += duracion) {
      const hours = Math.floor(i / 60);
      const mins = i % 60;
      result.push({ hora: `${hours.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}`, });
    }
    return result;
  }, [props.contacto?.inicio, props.contacto?.inicio1, props.contacto?.fin, props.contacto?.fin1]);
  
  
    // Generar disponibilidad semanal por día
  const disponibilidadPorDia = useMemo(() => {
    const diasSemana = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
    const semana = Array(7).fill().map(() => []);
    const disponibilidad = tempDisponibilidad || [];

    disponibilidad.forEach(({ dia, inicio, fin }) => {
      if (inicio !== undefined && fin !== undefined) {
        const diaIndex = diasSemana.indexOf(dia);
        if (diaIndex !== -1) {
          semana[diaIndex].push({ inicio, fin });
        }
      }
    });
    return semana;
  }, [ tempDisponibilidad]);
  
  

useEffect(() => {
//  !!contacto.creador && console.log(user.Usu_ID, contacto.creador);
  getEventos("leer", "user.Usu_ID");
  if (edit) {
    // Mantener la disponibilidad existente al entrar en modo edición
    setTempDisponibilidad([...rango_citas]);
    setEditStart(null); // Limpiar selección previa
  } else {
    // Restaurar disponibilidad original al salir de edición
    setTempDisponibilidad(rango_citas);
  }
}, []); //eslint-disable-line react-hooks/exhaustive-deps


	const DiaHeader = React.useCallback((dia) => {
	  let Dia = new Date(priDiaMes.valueOf() + 3600000 * 24 * dia);
	  let mes = Dia.getMonth() + 1;
	  return `${Dia.toLocaleDateString("es-PE", { weekday: "short", day: "numeric" })}/${mes}`;
	}, [priDiaMes]);

  const DiaEstilo = (dia) => {
    let diaSem = new Date(priDiaMes.valueOf() + 3600000 * 24 * dia).getDay();
    var Estilo = {
      paddingLeft: "0.5em",
      borderRight: diaSem === 0 ? "solid" : "none",
      borderRightColor: diaSem === 0 ? "#ccc" : "none",
      backgroundColor: diaSem === 0 || diaSem === 6 ? "#fcffd3" : "#f4f4f4",
      color: diaSem === 0 ? "red" : "#000",
    };
    return Estilo;
  };

  const DiaEstiloC = (dia) => {
    let diaSem = new Date(priDiaMes.valueOf() + 3600000 * 24 * dia).getDay();
    const baseStyle = { padding: "0", color: "#888", fontSize: "12px", textAlign: "center" };
    return diaSem === 0 ? { ...baseStyle, borderRight: "solid", borderRightColor: "#ccc" } : baseStyle;
  };


  const MostrarDialogo = (fechaCita, modo) => {
    const fechaInicio = new Date(fechaCita); // Usar directamente la fecha de la celda
    const fechaFin = new Date(fechaInicio.getTime() + 30 * 60 * 1000); // 30 minutos después
    const text = "Cita para " + props.contacto?.Nombre_Cat.toLowerCase() + " con " + props.contacto?.Nombre_Comercial;
    const textSocio = text + " en " + props.socio?.Nombre_Comercial;
    const socio = props.socio?.Contacto_ID || "";

    setModoBoton("nuevo");
    setVisible(true);
	setHoja(false);
    setFecha(fechaInicio);
    setFecha1(fechaFin);
    setEventoTitulo(user.Nombres + " " + user.ApellidoPaterno+ " " +user.ApellidoMaterno);
    setEventoDescripcion(socio ? textSocio : text);
    setChecked(false);
    setHasEnd(true);
    setColor("");
    setTextColor("");
    setId("");

    console.log('Fecha seleccionada:', fechaInicio.toLocaleString(), 'hasta', fechaFin.toLocaleString());
  };



const DiasTrabajoCalculo = (rowData, { diaIndex }) => {
  const hora = Number(rowData.hora.split(":")[0]);
  const minutos = Number(rowData.hora.split(":")[1]);
  const Dia = new Date(priDiaMes.valueOf() + 86400000 * diaIndex);
  const diaCal = new Date(Dia.setHours(hora, minutos, 0, 0));
  const horaDecimal = hora + minutos / 60;
  const diasSemana = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
  const diaSemanaIndex = Dia.getDay(); // Índice real del día según priDiaMes

  if (edit) {
    const tempIndex = diasSemana.indexOf(diasSemana[diaSemanaIndex]); // Índice correcto
    const diaActual = tempDisponibilidad[tempIndex];
    const inicio = diaActual.inicio !== undefined ? diaActual.inicio / 60 : null;
    const fin = diaActual.fin !== undefined ? diaActual.fin / 60 : null;
    const horaEnMinutos = hora * 60 + minutos;

    let estilo = {
      paddingTop: "0.5em",
      height: "2.5em",
      backgroundColor: "#fff",
      cursor: "pointer",
    };
    let diaEvento = "";
 //   let clic = "1";

    // Pintar celdas según el estado
    if (inicio !== null && fin !== null && horaDecimal >= inicio && horaDecimal < fin) {
      estilo.backgroundColor = "#b6ed77";
      diaEvento = "Disponible"; // Rango o segmento
    } else if (inicio !== null && fin === null && horaDecimal === inicio) {
      estilo.backgroundColor = "#b6ed77";
      diaEvento = "Inicio"; // Esperando fin
    }

    // Manejar clic para el flujo de selección
    const handleEditClick = () => {
      const newDisponibilidad = [...tempDisponibilidad];
      const currentInicio = newDisponibilidad[tempIndex].inicio;
      const currentFin = newDisponibilidad[tempIndex].fin;
      const editStartDiaIndex = editStart !== null
        ? tempDisponibilidad.findIndex(d => d.inicio === editStart && !d.fin)
        : -1;

      if (!currentInicio) {
        // 1er clic: establecer inicio
        if (editStartDiaIndex !== -1) {
          // Si hay un "Inicio" en otro día, completarlo como segmento
          newDisponibilidad[editStartDiaIndex] = {
            dia: diasSemana[editStartDiaIndex],
            inicio: editStart,
            fin: editStart + 30
          };
        }
        newDisponibilidad[tempIndex] = { dia: diasSemana[tempIndex], inicio: horaEnMinutos };
        setEditStart(horaEnMinutos);
      } else if (currentInicio && !currentFin) {
        if (horaEnMinutos === currentInicio) {
          // 2do clic en la misma celda: segmento de 30 minutos
          newDisponibilidad[tempIndex] = { dia: diasSemana[tempIndex], inicio: currentInicio, fin: currentInicio + 30 };
          setEditStart(null);
        } else if (tempIndex === editStartDiaIndex) {
          // 2do clic en el mismo día: formar rango
          const newInicio = Math.min(currentInicio, horaEnMinutos);
          const newFin = Math.max(currentInicio, horaEnMinutos) + 30;
          newDisponibilidad[tempIndex] = { dia: diasSemana[tempIndex], inicio: newInicio, fin: newFin };
          setEditStart(null);
        } else {
          // 2do clic en otro día: completar el día anterior y empezar nuevo
          newDisponibilidad[editStartDiaIndex] = {
            dia: diasSemana[editStartDiaIndex],
            inicio: editStart,
            fin: editStart + 30
          };
          newDisponibilidad[tempIndex] = { dia: diasSemana[tempIndex], inicio: horaEnMinutos };
          setEditStart(horaEnMinutos);
        }
      } else if (currentInicio && currentFin && horaEnMinutos === currentInicio) {
        // 3er clic en la misma celda de inicio: eliminar rango
        newDisponibilidad[tempIndex] = { dia: diasSemana[tempIndex] };
        setEditStart(null);
      } else if (currentInicio && currentFin) {
        // Clic en otro día con rango existente: empezar nuevo
        newDisponibilidad[tempIndex] = { dia: diasSemana[tempIndex], inicio: horaEnMinutos };
        setEditStart(horaEnMinutos);
      }
      setTempDisponibilidad(newDisponibilidad);
      console.log('tempDisponibilidad actualizado:', newDisponibilidad);
    };

    return { diaCal, estilo, diaEvento, clic: handleEditClick };
  } else {
    const eventosDia = agenda[diaIndex] || [];
    const eventoOcupado = eventosDia.find((ev) => {
      const evStart = new Date(ev.start);
      return (
        evStart.getDate() === Dia.getDate() &&
        evStart.getMonth() === Dia.getMonth() &&
        evStart.getFullYear() === Dia.getFullYear() &&
        evStart.getHours() === hora &&
        evStart.getMinutes() === minutos
      );
    });
    const isEvent = !!eventoOcupado;

    const diaSem = Dia.getDay();
    const horariosDia = disponibilidadPorDia[diaSem];
    const isAvailable = horariosDia?.some(
      ({ inicio, fin }) => horaDecimal >= inicio / 60 && horaDecimal < fin / 60
    );

    const isSunday = diaSem === 0;

    let estilo, diaEvento = "", clic = "";
    if (isEvent) {
      estilo = {
        paddingTop: "0.2em",
        height: "2em",
        backgroundColor: "#ff9891",
        color: "#FFF",
        overflow: "hidden",
        whiteSpace: "nowrap",
        borderRadius: "10px",
        margin: window.innerWidth > "800" ? "0px 10px" : "0px 3px",
		cursor: admin?"pointer":"",
      };
      clic = "2";	  
      diaEvento = "Ocupado";
    } else if (isSunday || !isAvailable) {
      estilo = { paddingTop: "0.5em", height: "2.5em", backgroundColor: "#f9f7f6" };
    } else {
      estilo = {
        paddingTop: "0.2em",
        height: "2em",
        backgroundColor: "#b6ed77",
        color: "#FFF",
        overflow: "hidden",
        whiteSpace: "nowrap",
        borderRadius: "10px",
        margin: window.innerWidth > "800" ? "0px 10px" : "0px 3px",
        cursor: admin?"":"pointer",
      };
      clic = "1";
      diaEvento = "Disponible";
    }

    // Retornar información adicional del evento si existe
    return { 
      diaCal, 
      estilo, 
      diaEvento, 
      clic, 
      evento: eventoOcupado || null // Añadir el evento completo o null si no hay
    };
  }
};
  
  
const DiasTrabajo = (diaIndex) => (rowData, column) => {
  let retorno = DiasTrabajoCalculo(rowData, { diaIndex });
  return (
    <div
      onClick={() => { 
        if (edit && typeof retorno.clic === "function") {
          retorno.clic();
        } else if (!edit && retorno.clic === "1") {
          !admin && MostrarDialogo(retorno.diaCal, "nuevo");
        } else if (!edit && retorno.clic === "2") { 
		  admin && setEventoActual(retorno.evento); // Guardar el evento actual
          admin && MostrarDialogo(retorno.diaCal, "leer"); // Pasar el evento
        }
      }}
      style={retorno.estilo}
    >
      <span className="textMovil">{retorno.diaEvento}</span>
    </div>
  );
};

  
  const EventosAgenda = (Events, diaUso) => {
    const eventosPorDia = Array(7).fill().map(() => []);
    Events.forEach((event) => {
      const start = new Date(event.start);
      const end = new Date(event.end);
      const title = event.title;
      const Usu_ID = event.Usu_ID;
      const descripcion = event.descripcion;
      const duration = (end - start) / 60000;

      // Filtrar solo citas ocupadas (≤ 30 min y Usu_ID !== "0")
      if (duration <= 30 && event.Usu_ID !== "0") {
        for (let c = 0; c < 7; c++) {
          const diaCal = new Date(diaUso.valueOf() + 86400000 * c);
          if (
            start.getDate() === diaCal.getDate() &&
            start.getMonth() === diaCal.getMonth() &&
            start.getFullYear() === diaCal.getFullYear()
          ) {
            eventosPorDia[c].push({ start, end, title, Usu_ID, descripcion });
            console.log(`Evento asignado: ${event.title} al día ${c} (${start.toLocaleString()})`);
            break;
          }
        }
      }
    });
    console.log('Eventos por día:', eventosPorDia);
    setAgenda(eventosPorDia);
  };


    const getEventos = async (accion, evento) => {
	setBarras(true);
    let resource = await serverEventos(accion, evento);
	console.log(resource.data);
    EventosAgenda(resource.data, priDiaMes);
    if (accion === "leer") setEvents(resource.data);
    if (accion !== "leer") setEvents(resource.data);
	setBarras(false);
  };
  

  const serverEventos = async (accion, evento) => {
    const config = { headers: { "content-type": "multipart/form-data" } };
    const formData = new FormData();
    formData.append("operacion", 15);
    formData.append("Contacto_ID", props.contacto?.Contacto_ID);
    formData.append("Socio_ID", props.socio?.Contacto_ID || "0");
    formData.append("accion", accion);
    if (evento !== undefined) {
      let st = evento.start;
      let mesSt = st?.getMonth() + 1;
      let end = evento.end;
      let mesEnd = end?.getMonth() + 1;
      formData.append("id", evento.id);
      formData.append("Usu_ID", user.Usu_ID);
      formData.append("title", evento.title);
      formData.append("descripcion", evento.descripcion);
      formData.append("start", `${st?.getFullYear()}-${mesSt}-${st?.getDate()} ${st?.getHours()}:${st?.getMinutes()}:${st?.getSeconds()}` );
      formData.append("end", `${end?.getFullYear()}-${mesEnd}-${end?.getDate()} ${end?.getHours()}:${end?.getMinutes()}:${end?.getSeconds()}`);
      formData.append("allDay", evento.allDay);
      formData.append("hasEnd", evento.hasEnd);
      formData.append("color", evento.Color);
      formData.append("textColor", evento.textColor);
    }
    return await axios.post(api, formData, config);
  }; 

const serverCitas = async (citas) => { 
    const config = { headers: { "content-type": "multipart/form-data" } };
    const formDataContactoSave = new FormData();
    formDataContactoSave.append('operacion', '15.3');
    formDataContactoSave.append('Contacto_ID', props.contacto.Contacto_ID);
    formDataContactoSave.append('rango_citas', JSON.stringify(citas));
    return await axios.post(api, formDataContactoSave, config);
  };  
  

  const onChecked = (e) => {
    setChecked(e.checked);
    setEventoTitulo(user.Nombres + " " + user.ApellidoPaterno+ " " +user.ApellidoMaterno);
  };

  
  const ActualizaEventos = (mando) => {
    if (modoBoton === "edita") mando = "edita";
    var eventoNuevo = {
      id: id,
      title: eventoTitulo,
      descripcion: eventoDescripcion,
      start: fecha,
      end: fecha1,
      allDay: false,
      hasEnd: hasEnd,
      Color: Color,
      textColor: textColor,
    };
    if (mando === "agrega") getEventos("agregar", eventoNuevo);
    if (mando === "borra") {
      if (window.confirm("¿Está seguro que desea borrar el evento?")) {
        getEventos("borrar", eventoNuevo);
      } else {
        mando = "error";
      }
    }
    if (mando === "edita") getEventos("editar", eventoNuevo);
    if (mando !== "error") {
      setVisible(false);
      setModoBoton("nuevo");
    } else {
      setModoBoton("nuevo");
    }
  };
  
  
  
  
  const borrar = () => {
    setTempDisponibilidad([
      { dia: "Domingo" },
      { dia: "Lunes" },
      { dia: "Martes" },
      { dia: "Miércoles" },
      { dia: "Jueves" },
      { dia: "Viernes" },
      { dia: "Sábado" },
    ]);
    setEditStart(null);
  };    
  
  const editar = () => {
    setEdit(!edit);
  };  
  
    const cliente = async(Usu_ID) => {
	let pedir = usuario.Usu_ID === Usu_ID;
    setHoja(!hoja);	
	if (!pedir){
	setUsuario("");
	let resource = await InfoData('user', {user:{Usu_ID:Usu_ID}});
    setUsuario(resource);}
  };  
  
const guardar = async() => {
  // Filtrar días con rangos completos (inicio y fin definidos)
  const disponibilidadCompleta = tempDisponibilidad.map(dia => {
    if (dia.inicio !== undefined && dia.fin === undefined) {
      return { dia: dia.dia }; // Ignorar segmento incompleto
    }
    return dia;
  });
  setTempDisponibilidad(disponibilidadCompleta); // Actualizar estado con rangos completos
  setEdit(!edit);
  dispatch({ type: types.contacto, payload: {...contacto, rango_citas:disponibilidadCompleta} });
  let estatus = await serverCitas(disponibilidadCompleta);
  console.log('Disponibilidad guardada:', disponibilidadCompleta);
  console.log(tempDisponibilidad, "El estado es "+estatus)

  };   

 const icoPerfil = (evento) => ( admin && <span style={{ position: 'absolute', right:'1.5em', fontSize:'28px', color:hoja?"#ff9891":'#607d8b', cursor: "pointer" }}><i onClick={(e) => { hoja?setHoja(!hoja):cliente(evento.Usu_ID) }} className={hoja?"fa fa-calendar-check":"far fa-id-card"} > </i></span>);
 const eDescripcion = <span><InputTextarea style={{width: '100%'}} label='Descripción'  value={eventoDescripcion} onChange={(e) => setEventoDescripcion(e.target.value)} autoResize={true} rows={1} cols={30}/></span>;
  
 const citas = (evento) => (
  <div className="p-grid dialogoFormulario">
    <div className="p-col-12 p-md-12"><span><label className="topLabel">Contacto</label><span>{props.socio?.Contacto_ID ? props.socio.Nombre_Comercial + ' (' + props.contacto?.Nombre_Cat + ')' : props.contacto?.Nombre_Comercial + ' (' + props.contacto?.Nombre_Cat + ')'}</span></span></div>
    <div className="p-col-12 p-md-12"><span><label className="topLabel">Cliente</label><span>{evento ? evento.title : (user.Nombres + " " + user.ApellidoPaterno + " " + user.ApellidoMaterno)}</span>{icoPerfil(evento)}</span></div>
    <div className="p-col-12 p-md-12"><span><label className="topLabel">Fecha del evento</label><span>{new Date(evento ? evento.start : fecha).toLocaleDateString("es-PE", { weekday: "long", month: "long", day: "numeric", hour: "numeric", minute: "numeric" }) + ' horas'}</span></span></div>
    <div className="p-col-12 p-md-12"><span><label className="topLabel">Descripción</label><span style={{ textAlign: 'justify' }}>{evento ? evento.descripcion : eDescripcion}</span></span></div>
    {!evento && ( // Mostrar checkbox solo en modo registro
      <div className="p-col-12 p-md-12"><Checkbox checked={checked} onChange={onChecked} /><div style={{ marginTop: '-1.5em', fontSize: '12px', color: '#898989', marginLeft: '2.3em' }}>Acepto los <a href='index.html'>términos y condiciones</a> para realizar la reserva de la cita</div></div>
    )}
  </div>
);
	
  const WA = usuario.Celular?<a href={'https://wa.me/'+usuario.DDI+usuario.Celular} target="_blank" rel="noreferrer" style={{ position: 'absolute', right:'2em' }}><i className="fab fa-whatsapp-square icoPag" style={{color: '#44e561', fontSize:"auto", verticalAlign:'top'}}></i></a>:'';

  let perfil = ( 
	<div className="p-grid dialogoFormulario">
        {false && <div className="p-col-12 p-md-12" > <span>{usuario &&  <img width='150' src={usuario.interno?(ruta+usuario.Foto):usuario.Foto} alt="Foto de perfil"/> }</span></div> }
        <div className="p-col-12 p-md-12" > <span> <label className="topLabel">Nombre Completo</label> <span >{usuario && usuario.Nombres+' '+usuario.ApellidoPaterno+' '+usuario.ApellidoMaterno}</span> {icoPerfil()} </span></div> 
        <div className="p-col-12 p-md-12" > <span> <label className="topLabel">Correo electrónico</label> <span >{usuario.Email || "" }</span>   </span></div> 
        <div className="p-col-12 p-md-12" > <span> <label className="topLabel">Teléfono Celular</label> <span> {usuario.Celular || ""} </span>  {WA}  </span></div>
        <div className="p-col-12 p-md-12" > <span> <label className="topLabel">Ubicación</label>  <span style={{textAlign: 'justify'}}>{usuario && 'Distrito de '+usuario.Distrito+', provincia de '+usuario.Provincia+', departamento de '+usuario.Departamento+' - '+usuario.Pais}</span> </span></div>	
		<div className="p-col-12 p-md-12" > {!usuario&&<BarrasSmall/>}</div>
    </div>
 	); 
  
  let headerGroup = (
	<ColumnGroup>
	  <Row>
		<Column header="Horas" rowSpan={2} />
		<Column header={<span style={{alignItems: 'center', display: 'flex',justifyContent: 'space-between'}}>
		<div>
			<Button className="botonAgenda" icon="pi pi-fw pi-caret-left" onClick={(e) => {setPriDiaMes(priDiaMes-86400000*7);EventosAgenda(events,priDiaMes - 86400000*7);}} style={{marginRight: '0.2em'}}/>
			<Button className="botonAgenda" icon="pi pi-fw pi-caret-right" onClick={(e) => {setPriDiaMes(priDiaMes+86400000*7); EventosAgenda(events,priDiaMes + 86400000*7);}} style={{marginRight: '0.2em'}}/>
			<Button className="botonAgenda" label="Hoy" onClick={(e) => {setPriDiaMes(hoy); EventosAgenda(events,hoy);}} style={{ width:'4em'}}/>
		</div>
		<div> {new Date(priDiaMes.valueOf()+3600000*24*1).toLocaleDateString("es-PE",{month: "long", year: "numeric"})}
		</div>
		<div>
			{!!edit &&
			<Button className="botonAgenda" icon="pi pi-fw fa fa-trash-alt"   onClick={(e) => { borrar() }} />}
			{admin &&
			<Button className="botonAgenda" icon={"pi pi-fw " + (edit ? "fa fa-save" : "far fa-edit")}   onClick={(e) => { edit ? guardar() : editar() }} style={{ margin:'0 5px 0'}}/>}			
			<Calendar id='fechador' className="movil500mas"  value={priDiaMes} onChange={(e) => setPriDiaMes(e.value.valueOf())} showIcon={true} />
		</div></span>}  colSpan={7} />
	  </Row>
	  <Row>
		<Column header={ DiaHeader(0)} style={ DiaEstilo(0)} />
		<Column header={ DiaHeader(1)} style={ DiaEstilo(1)} />
		<Column header={ DiaHeader(2)} style={ DiaEstilo(2)} />
		<Column header={ DiaHeader(3)} style={ DiaEstilo(3)} />
		<Column header={ DiaHeader(4)} style={ DiaEstilo(4)} />
		<Column header={ DiaHeader(5)} style={ DiaEstilo(5)} />
		<Column header={ DiaHeader(6)} style={ DiaEstilo(6)} />
	  </Row>
	</ColumnGroup>
	);
const footer =  !hoja && (
	<div>
		<Button label="Cancelar" icon="pi pi-times" className="p-button-secondary  dialogoBoton" onClick={() => setVisible(false)} />
		{!admin ?
		<Button label="Guardar" icon="pi pi-check" className="p-button-raised  dialogoBoton" onClick={e =>  ActualizaEventos('agrega')} disabled={!checked}/>:
		<Button label="Confirmar" icon="pi pi-check" className="p-button-raised  dialogoBoton" onClick={e =>  alert('Confirmado')} />}
	</div>
	); 
return (
  <div className='acerca' style={{...props.style, border:'0'}}>
  <h2 className='movil500mas' style={{margin:'-1.0em 0 1em'}}>
	{'Reservar una cita en ' + props.contacto?.Nombre_Cat +' con '+ props.contacto?.Nombre_Comercial}
  </h2>
    <div id="calendario" style={{width:'100%',minHeight: '500px'}}>
	{barras && <Barras/>}
      <div className="p-grid">
        <DataTable className="Roster" value={turnos} editable={true} headerColumnGroup={headerGroup} >
          <Column field="hora" header="Hora" style={{width:'15%',textAlign:'center'}} />
            <Column body={DiasTrabajo(0)} style={DiaEstiloC(0)} />
            <Column body={DiasTrabajo(1)} style={DiaEstiloC(1)} />
            <Column body={DiasTrabajo(2)} style={DiaEstiloC(2)} />
            <Column body={DiasTrabajo(3)} style={DiaEstiloC(3)} />
            <Column body={DiasTrabajo(4)} style={DiaEstiloC(4)} />
            <Column body={DiasTrabajo(5)} style={DiaEstiloC(5)} />
            <Column body={DiasTrabajo(6)} style={DiaEstiloC(6)} />
        </DataTable>  
      </div>   
    </div>
    <Dialog header={user?.Usu_ID!=null?"Resumen de tu reserva":""} visible={visible} style={{width: user?.Usu_ID?'480px':'auto', background:'#ffffff', borderRadius:'0.3rem' }} footer={user?.Usu_ID?footer:''} onHide={() => setVisible(false)}>
	{ hoja ? ( perfil ) : admin ? ( citas(eventoActual) ) : user?.Usu_ID != null ? ( citas(null) ) : ( <LogForm ajuste='1' /> ) }
    </Dialog>			
  </div>
  );
}  

//{ hoja ? perfil : {admin ? citas(eventoActual) : (user?.Usu_ID != null ? citas(null) : <LogForm ajuste='1' />)} }

