import React, { useContext, useState, /*useEffect,*/ Fragment, forwardRef, useImperativeHandle, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { AutoComplete } from "primereact/autocomplete";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { InfoData } from "./InfoData";
import { StoreContext } from "./store/StoreProvider";
import { types } from "./store/StoreReducer";
import { Calendario } from "./Calendario";
import { UploadForm } from "./Uploadform";
import { Barras } from './components/loaders/Barras';
import './layout/Perfil.css';


const UbicacionForm = forwardRef((props, ref) => {
	const {store, dispatch } = useContext(StoreContext);
	const {  user, paises, ciudades, provincias, distritos, ruta, img } = store;
	const [ubicacionesf, setUbicacionesf] = useState([{ label: "", value: "" }]);
	const [ubicacionB, setUbicacionB] = useState({label:user.Distrito+', '+user.Provincia+' - '+user.Departamento+' - '+user.Pais, value:user.Distrito_ID});  
	const [ubicaAutMan, setUbicaAutMan] = useState(true); 
	const ac = document.getElementsByClassName("p-inputtext p-component p-autocomplete-input")[0];
	
	  const onCountryChange = (e) => {
    obtenerciudades(e.value);
	dispatch({ type: types.authLogin, payload: { ...user, Pais_ID: e.value, Departamento_ID: '', Provincia_ID: '', Distrito_ID: '',
	  Distrito:'', Provincia:'', Departamento:'', Pais:''}, });	
  };

  const onCiudadChange = (e) => {
    if (user.Pais_ID === "89") {
      obtenerProvincias(e.value);
    } else {
      obtenerDistritos("", e.value);
    }
	dispatch({ type: types.authLogin, payload: { ...user, Departamento_ID: e.value, Provincia_ID: '', Distrito_ID: '',
	  Distrito:'', Provincia:'', Departamento:''}, });		
 //   document.getElementsByClassName( "p-dropdown-label p-inputtext p-placeholder" )[0].style.background = "#ffffff";
  };

  const obtenerciudades = async (pais) => {
    let db = await InfoData(types.ciudades, pais);
    dispatch({ type: types.ciudades, payload: db.ciudades });
  };

  const onProvinciaChange = (e) => {
    obtenerDistritos(e.value, "");
	dispatch({ type: types.authLogin, payload: { ...user, Provincia_ID: e.value, Distrito_ID: '',
	  Distrito:'', Provincia:'', Departamento:''}, });		
  };

  const obtenerProvincias = async (dpto) => {
    let db = await InfoData(types.provincias, dpto);
    dispatch({ type: types.provincias, payload: db.provincias });
  };

  const onDistritoChange = (e) => {
	dispatch({ type: types.authLogin, payload: { ...user, Distrito_ID: e.value, Distrito:'' }, });	  
 //   document.getElementsByClassName( "p-dropdown-label p-inputtext p-placeholder" )[2].style.background = "#ffffff";
    obtenerUbicacion(e.value);
  };

  const obtenerDistritos = async (prov, dpto) => {
    let db = await InfoData(types.distritos, { prov: prov, dpto: dpto });
    dispatch({ type: types.distritos, payload: db.distritos });
  };

  const obtenerUbicacion = async (distrito_ID) => {
	let resource = await InfoData('ubicacion', {nombre:'', distrito_ID:distrito_ID});	
    dispatch({ type: types.ubicacion, payload: resource.data[0] });
    dispatch({ type: types.authLogin, payload: { ...user, Pais_ID: resource.data[0].Pais_ID, 
	Departamento_ID: resource.data[0].Departamento_ID, Provincia_ID: resource.data[0].Provincia_ID,
	Distrito_ID: resource.data[0].Distrito_ID,
	Distrito:resource.data[0].Distrito, Provincia:resource.data[0].Provincia, Departamento:resource.data[0].Departamento, Pais:resource.data[0].Pais
	}, });
  };

  const ubicacionChange = async(e) => {
    setUbicacionB(e.value);
    let country = e.value.Pais_ID;
    let depart = e.value.Departamento_ID;
    let province = e.value.Provincia_ID;
    let distrit = e.value.Distrito_ID;
    if (typeof country !== "undefined"){
    await dispatch({ type: types.authLogin, payload: { ...user, Pais_ID: country, Departamento_ID: depart, Provincia_ID: province, Distrito_ID: distrit,
	  Distrito:e.value.Distrito, Provincia:e.value.Provincia, Departamento:e.value.Departamento, Pais:e.value.Pais}, });
      obtenerciudades(country);
      if (country === "89") {
        obtenerProvincias(depart);
        obtenerDistritos(province, "");
      } else {
        obtenerDistritos("", depart);
      }
    }
  };

  const filtroubicaciones = async (event) => {
    var resource = [];
    var ubica = [];
    if (event.query.length >= 1) {
	resource = await InfoData('ubicacion', {nombre:event.query.toLowerCase(), distrito_ID:''});
      ubica = resource.data;
    }
    setTimeout(() => {
      var results = ubica.filter((ubicacionB) => {
        return ubicacionB.label.toLowerCase();
      });
      setUbicacionesf(results);
    }, 350);
  };

  const selectedCountryTemplate = (option, props) => {
    if (option) {
        return (
            <div className="country-item country-item-value">
                <img alt={option.label} src={ruta+img.flag} onError={e => e.target.src = ruta+img.flagEr} className={`flag flag-${option.code.toLowerCase()}`} />
                <div style={{flex: 'none'}}>{option.label}</div>
            </div>
        );
    }

    return ( <span> {props.placeholder} </span>
    );
  }



const countryOptionTemplate = (option) => {
    return (
        <div className="country-item">
            <img alt={option.label} src={ruta+img.flag} onError={(e) => e.target.src=''} className={`flag flag-${option.code.toLowerCase()}`} />
            <div style={{flex: 'none'}}>{option.label}</div>
        </div>
    );
  }


  const acEstado = (e) => {
    if (user.Departamento_ID === "") {
      ac.style.background = "#fff2ef";
    } else {
      ac.style.background = "#ffffff";
    }
  };
	
	
  const paisEstado = (e) => {
    if (user.Pais_ID === "") {
      document.getElementsByClassName("p-dropdown-label p-inputtext")[0].style.background = "#fff2ef";
    } else {
      document.getElementsByClassName("p-dropdown-label p-inputtext")[0].style.background = "#ffffff";
    }
  };

  const dptoEstado = (e) => {
    if (user.Departamento_ID === "") {
      document.getElementsByClassName("p-dropdown-label p-inputtext")[1].style.background = "#fff2ef";
    } else {
      document.getElementsByClassName("p-dropdown-label p-inputtext")[1].style.background = "#ffffff";
    }
  };

  const proviEstado = (e) => {
    if (user.Provincia_ID === "") {
      document.getElementsByClassName("p-dropdown-label p-inputtext")[2].style.background = "#ffffff";
    } else {
      document.getElementsByClassName("p-dropdown-label p-inputtext")[2].style.background = "#ffffff";
    }
  };

  const distriEstado = (e) => {
    if (user.Distrito_ID === "") {
      document.getElementsByClassName("p-dropdown-label p-inputtext")[3].style.background = "#ffffff";
    } else {
      document.getElementsByClassName("p-dropdown-label p-inputtext")[3].style.background = "#ffffff";
       }
  };
  
  	
	
	
   return (
        <form>
		  <ul className="input-fields container-box">
			{!ubicaAutMan && <>
			<li className="cell-input">
			<span style={{marginLeft :'5px',cursor:'pointer', float:'right'}}  onClick={() => setUbicaAutMan(!ubicaAutMan)}><i className='pi pi-search' style={{marginRight :'15px'}} tooltip="Ingresar ubicación manualmente"/>Búsqueda automática</span>
			  <div className="cell-body"><label className="float-label" htmlFor="inpais">País</label>
			  <Dropdown value={user.Pais_ID} options={paises} onChange={onCountryChange} placeholder="Pais" onBlur={() => paisEstado()}
			  valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate} id="inpais" /></div>
			</li>
			<li className="cell-input">
			  <div className="cell-body"><label className="float-label" htmlFor="indepa">Departamento</label> 
			  <Dropdown value={user.Departamento_ID} options={ciudades} onChange={onCiudadChange} placeholder="Departamento" id="indepa" onBlur={() => dptoEstado()}/></div>
			</li>
			<li className="cell-input" style={{'display':(user.Pais_ID==='89')?'block':'none'}}>
			  <div className="cell-body"><label className="float-label" htmlFor="inprov">Provincia</label>
			  <Dropdown value={user.Provincia_ID} options={provincias} onChange={onProvinciaChange} placeholder="Provincia" id="inprov" onBlur={() => proviEstado()}/></div>
			</li>
			<li className="cell-input" >
			  <div className="cell-body"><label className="float-label" htmlFor="indistrit">Distrito</label>
			  <Dropdown value={user.Distrito_ID} options={distritos} onChange={onDistritoChange} placeholder="Distrito" id="indistrit" onBlur={() => distriEstado()}/></div>
			</li></>}
			{ubicaAutMan &&
			<li className="cell-input" >
			  <div className="cell-body"><label className="float-label" htmlFor="inubicacion">Ubicación automática<span style={{marginLeft :'5px',cursor:'pointer', float:'right'}}  onClick={() => setUbicaAutMan(!ubicaAutMan)}><i className='pi pi-pencil' style={{marginRight :'15px',cursor:'pointer'}} tooltip="Ingresar ubicación manualmente"/>Búsqueda manual</span></label>
			  <AutoComplete id="inubicacion" value={ubicacionB} suggestions={ubicacionesf} completeMethod={filtroubicaciones} field="label" size={30} placeholder="Escribe el nombre de tu distrito" minLength={1} onChange={ubicacionChange}  onBlur={() => acEstado()} style={{'background':'#ffffff'}}/></div>
			  <small id="inubicacion-help" className="p-d-block">{ user.Pais_ID ?  'Busqueda de distritos en '+user.Pais :'Busqueda de distritos en todo el mundo'}</small>
			</li>}
		  </ul>
        </form>
    );
});


const PasswordForm = forwardRef((props, ref) => {
    // Estados para los inputs
	const {store } = useContext(StoreContext);
	const { user } = store;
    const [password1, setPassword1] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    

    // Estado para controlar si se muestran las contraseñas
    const [verPwd, setVerPwd] = useState(false);

    // Función para validar el password nuevo
    const validarPwdNuevo = (valor, revalor) => {
	
        if (valor === "") {
            alert("La contraseña no puede estar vacía.");
            return false;
        }	
	
        const validezLong = valor.length >= 5;
        if (!validezLong) {
            alert("La contraseña debe tener más de 5 caracteres.");
            return false;
        }

        const validezDoble = valor === revalor;
        if (!validezDoble) {
            alert("Las contraseñas no coinciden.");
            return false;
        }

/*        // Validar que tenga al menos una mayúscula y un número
        const tieneMayuscula = /[A-Z]/.test(valor);
        const tieneNumero = /[0-9]/.test(valor);

        if (!tieneMayuscula) {
            alert("La contraseña debe tener al menos una letra mayúscula.");
            return false;
        }

        if (!tieneNumero) {
            alert("La contraseña debe tener al menos un número.");
            return false;
        }
*/
        return true;
    };

    // Función para validar el password actual del usuario
    const validarPwdUsuario = async (User) => {
        let resource = await InfoData('disponibilidadUsr', {
            usuario: '',
            celular: '',
            email: '',
            pwd: User,
            Usu_ID: user.Usu_ID,
        });
        const passEscrito = resource.data.disponibilidad === 'ocupado';
        if (!passEscrito) {
            alert("Contraseña incorrecta.");
        }
        return passEscrito;
    };

	
  const passEstado = async (User) => {
        let resource = await InfoData('disponibilidadUsr', {
            usuario: '',
            celular: '',
            email: '',
            pwd: User,
            Usu_ID: user.Usu_ID,
        });
        const passEscrito = resource.data.disponibilidad === 'ocupado';
        props.setPassValid(passEscrito);
  };	
	
	
    // Función para validar todos los campos y guardar
    const guardar = async () => {
        // Validar password actual
        const passUserActualValido = await validarPwdUsuario(password1);
        if (!passUserActualValido) {
            return {value:false,password:password};
        }

        // Validar password nuevo
        const passNuevoOk = validarPwdNuevo(password, password2);
        if (!passNuevoOk) {
            return {value:false,password:password};
        }

        // Si todo está bien
        return {value:true,password:password};
    };
	
    const cancel = () => { 
	setPassword('');
	setPassword1('');
	setPassword2('');
	setVerPwd(false);
	}
	

    // Exponer la función guardar al componente padre
    useImperativeHandle(ref, () => ({ guardar, cancel }));

    return (
        <form>
		    <input type="text" name="username" autoComplete="username" style={{ display: 'none' }} defaultValue={user.usuario} />  
			{/* Asigna el nombre de usuario actual si está disponible */}
            <ul>
                <li className="cell-input">
                    <div className="cell-body">
                        <label className="float-label" htmlFor="inpwd1">
                            Contraseña actual
                        </label>
                        <span className="p-input-icon-right">
                            <i
                                className={verPwd ? 'pi pi-eye-slash' : 'pi pi-eye'}
                                style={{ right: '0.5em', cursor: 'pointer', fontSize: '2em', top: '0.55em' }}
                                onClick={() => setVerPwd(!verPwd)}
                            />
                            <InputText
                                id="inpwd1"
                                type={verPwd ? 'text' : 'password'}
                                value={password1}
                                onChange={(e) => setPassword1(e.target.value)}
                                aria-describedby="inpwd1-help"
								onBlur={() => passEstado(password1)}
								autoComplete="current-password"
                            />
                        </span>
                    </div>
                </li>
                <li className="cell-input">
                    <div className="cell-body">
                        <span style={{ color: '#00adef', cursor: 'pointer' }}>¿Olvidaste tu contraseña?</span>
                    </div>
                </li>
                <li className="cell-input">
                    <div className="cell-body">
                        <label className="float-label" htmlFor="inpwd">
                            Contraseña nueva
                        </label>
                        <span className="p-input-icon-right">
                            <i
                                className={verPwd ? 'pi pi-eye-slash' : 'pi pi-eye'}
                                style={{ right: '0.5em', cursor: 'pointer', fontSize: '2em', top: '0.55em' }}
                                onClick={() => setVerPwd(!verPwd)}
                            />
                            <InputText
                                id="inpwd"
                                type={verPwd ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                aria-describedby="inpwd-help"
								autoComplete="new-password"
                            />
                        </span>
                    </div>
                </li>
                <li className="cell-input">
                    <div className="cell-body">
                        <label className="float-label" htmlFor="inpwd2">
                            Repetir contraseña nueva
                        </label>
                        <InputText
                            id="inpwd2"
                            type={verPwd ? 'text' : 'password'}
                            value={password2}
                            onChange={(e) => setPassword2(e.target.value)}
                            aria-describedby="inpwd2-help"
							autoComplete="new-password"
                        />
                    </div>
                </li>
            </ul>
        </form>
    );
});




export const Perfil = (props) => {
  const passwordFormRef = useRef(null);
  const {store, dispatch} = useContext(StoreContext);
  const { user, ruta, img, testMail } = store;
  const [usuarioDisp, setUsuarioDisp] = useState("libre");
  const [buffer] = useState({ user: user });
  const generos = [
    { label: "Masculino", value: "M" },
    { label: "Femenino", value: "F" }];
  const [emailDisp, setEmailDisp] = useState("libre");
  const [celularDisp, setCelularDisp] = useState("libre");
  const [imagen, setImagen] = useState("0");
  const [cajaImg, setCajaImg] = useState("");
  const [mostrar, setMostrar] = useState("");
  const [desactiva, setDesactiva] = useState("");   
  const [elimina, setElimina] = useState("");
  const [barras, setBarras] = useState(false);
  const [mando, setMando] = useState({ select: "perfil" });
  const [editar, setEditar] = useState("");
  const [passValid, setPassValid] = useState(false);	
  const fotoF = ruta + img.fotoF;
  const fotoM = ruta + img.fotoM;
  const imagenPerfil = [
    { genero: "M", value: "1", img: fotoM },
    { genero: "F", value: "2", img: fotoF } ];
  document.documentElement.style.setProperty('--altoCol', '1.50em 0.5em');


  const fotoupload = () => {
    //crear una ventana y montar un componente subidor de archivos, en esta ventana.
    setMostrar("ok");
  };

  const SuccessUpload = async (data, usu_ID, tipo) => {
	let resX = await InfoData('foto', { data:data,usu_ID:usu_ID,tipo:tipo});
	console.log(resX);
    switch (tipo) {
      case "0":
        dispatch({ type: types.authLogin, payload: { ...user, Foto: resX.data }, });
        break;
        default: 
        console.log("No hay coincidencias");
    }
    setMostrar("");	
  };


  const onUsuarioChange = (e) => {
    disponibilidadUsuario(e.target.value);
    dispatch({type: types.authLogin, payload: { ...user, Usuario: e.target.value },});
  };

  const onCelularChange = (e) => {
    disponibilidadCelular(e.target.value);
    dispatch({type: types.authLogin, payload: { ...user, Celular: e.target.value },});
  };

  const validarCelular = (valor, caso) => {
    let validez = valor.length > 8  
	caso && !validez && alert('Teléfono móvil con digitos insuficientes');
    return validez;
  };

  const onEmailChange = (e) => {
    disponibilidadEmail(e.target.value);
    dispatch({type: types.authLogin, payload: { ...user, Email: e.target.value },});
  };

  const validarEmail = (valor, caso) => {
    let validez = testMail.test(valor);
	caso && !validez && alert('El correo no tiene el formato apropiado');
    return validez;
  };

  
  const disponibilidadUsuario = async (User) => {
    let resource = await InfoData('disponibilidadUsr', {usuario:User,celular:'',email:'',pwd:'',Usu_ID:user.Usu_ID });	
    if (resource.data.disponibilidad === "ocupado") {
      document.getElementById("inuser").style.backgroundColor = "#fff2ef";
    } else {
      document.getElementById("inuser").style.backgroundColor = "#FFFFFF";
    }
    setUsuarioDisp(resource.data.disponibilidad || "");
    return resource.data.disponibilidad;
  };

  const disponibilidadEmail = async (User) => {
    let resource = await InfoData('disponibilidadUsr', {usuario:'',celular:'',email:User,pwd:'',Usu_ID:user.Usu_ID})	
    if (resource.data.disponibilidad === "ocupado") {
      document.getElementById("inemail").style.backgroundColor = "#fff2ef";
    } else {
      document.getElementById("inemail").style.backgroundColor = "#FFFFFF";
    }
    setEmailDisp(resource.data.disponibilidad || "");
    return resource.data.disponibilidad;
  };

  const disponibilidadCelular = async (User) => {
    let resource = await InfoData('disponibilidadUsr', {usuario:'',celular:User,email:'',pwd:'',Usu_ID:user.Usu_ID})
    if (resource.data.disponibilidad === "ocupado") {
      document.getElementById("incel").style.backgroundColor = "#fff2ef";
    } else {
      document.getElementById("incel").style.backgroundColor = "#FFFFFF";
    }
    setCelularDisp(resource.data.disponibilidad || "");
    return resource.data.disponibilidad;
  };

 
  const desactivar = (e) => {
  setDesactiva(e);
  setElimina('');    
  setTimeout(e==='desactivar'?alert('Tu cuenta quedará desactivada temporalmente'):'', 1000)
  }


  const eliminar = (e) => {
  setElimina(e);  
  setDesactiva('');  
  setTimeout(e==='eliminar cuenta'?alert('Tu cuenta se eliminará permanentemente'):'', 1000)
  }
  
  
  const validacion = async (caso) => {
  
    if (caso === "pwd") {
         if (passwordFormRef.current) {
			const estado = await passwordFormRef.current.guardar();
			const esValido = estado.value;
            const Password = estado.password;
             if (esValido) {
                // Aquí puedes agregar la lógica para guardar los datos

			return {esValido:esValido, Password:Password};
            }else { return '';}
        }
    }  
     if (caso === "fot") {
	return 'ok'
	}   
     if (caso === "ubi") {
	return 'ok'
	}
     if (caso === "usu") {
		let usuarioVal = '';
		if (buffer.user.Usuario !== user.Usuario) { 
			usuarioVal = await disponibilidadUsuario(user.Usuario); 
			if ([usuarioVal].includes("ocupado")) {
			alert('El usuario ya esta registrado');
			dispatch({type: types.authLogin, payload: { ...user, Usuario: buffer.user.Usuario },});			
			return '';	
			} 			
			else {
					return 'ok';
			};	 
			
		}	else {
					return '';
			};	 
	}
    if (caso === "cto") {
		let formato = validarEmail(user.Email, true);
		if(!formato){return '';}
		let formaCel = validarCelular(user.Celular, true);
		if(!formaCel){return '';}
		let emailVal = ''; 
		if (buffer.user.Email   !== user.Email)  { emailVal = await disponibilidadEmail(user.Email); };
		let celularVal = '';
		if (buffer.user.Celular !== user.Celular) { celularVal = await disponibilidadCelular(user.Celular); };	

		if ([ emailVal, celularVal].includes("ocupado")) {
		  //		alert('El usuario ya esta registrado');
		let b = "";
		if (emailVal === "ocupado") b = "o";
		let c = "";
		if (celularVal === "ocupado") c = "o";

		if (b || c) {
		alert(
		  "Este " +
			(b ? "Correo electrónico" : "") +
			(b && c ? ", " : "") +
			(c ? "teléfono móvil" : "") +
			(b && c ? " ya se encuentran registrados con otro usuario." : " ya se encuentra registrado con otro usuario.")
		);
		}
		return '';	  
		} else {
		return 'ok' 
		}	
	} 	
     if (caso === "nom") {
		if ([user.Nombres, user.ApellidoPaterno].includes("")) {
		  alert('Rellene los campos obligatorios');
		return ''
		} else	 {
		return 'ok' 
		}
	}
	if (caso === "del") {
	return 'ok'
	}
  };

  const guardar = async (caso) => {
  setBarras(true);
    let datosValidos = await validacion(caso);
	let Password = caso === 'pwd' ? datosValidos.Password:'';
	datosValidos = caso === 'pwd' ? (datosValidos?.esValido ? "ok" : "") : datosValidos;
	console.log(Password);
	if (caso!=='del'){
    if (datosValidos === "ok") {
      let resource = await InfoData('user', {user:user,caso:caso,pwd:Password});
	  if(caso==='fot'){console.log(user);  resource = user}
//      console.log(resource);
      if (resource.Usu_ID !== "") {
        alert( resource.Nombres + " " + resource.ApellidoPaterno + " " + resource.ApellidoMaterno +
            " actualizó su información");
		dispatch({type: types.authLogin, payload: resource,});	
//		localStorage.setItem('user', JSON.stringify(user));

      }
	caso ==='pwd' && passwordFormRef.current.cancel();
    }else{console.log('No se actualizó nada');}
	}else{alert('Tu cuenta ha sido marcada para eliminarse')}
	cierreCase(caso);
  setBarras(false);	
  };


  const editarForm = (caso) => {
	document.getElementById("menuMovil").classList.add('oculto');
    switch (caso) {
      case 0:
        setMando({ ...mando, select: "perfil" });
        break;
      case 1:
        setEditar("");
        setMando({ ...mando, select: "agenda" });
        break;
      case 2:
        setEditar("");
        setMando({ ...mando, select: "ficha" });
        break;
        default: 
        console.log("No hay coincidencias");
    }
  };

  const editarCase = (caso) => {
    let a = document.getElementsByClassName(caso);
    let b = ["fot", "nom", "usu", "cto", "ubi", "pwd", "del"];
    a[0].classList.add("oculto");
    a[1].classList.remove("oculto");
    a[2].classList.add("oculto");
    a[3].classList.remove("oculto");
    for (let n in b) {
      if (b[n] !== caso) {
        cancelCase(b[n]);
      }
    }
  };
  
  const cierreCase = (caso) => {
    let a = document.getElementsByClassName(caso);
    a[0].classList.remove("oculto");
    a[1].classList.add("oculto");
    a[2].classList.remove("oculto");
    a[3].classList.add("oculto");
  };

  const cancelCase = (caso) => {
    let a = document.getElementsByClassName(caso);
    a[0].classList.remove("oculto");
    a[1].classList.add("oculto");
    a[2].classList.remove("oculto");
    a[3].classList.add("oculto");
    dispatch({ type: types.authLogin, payload: buffer.user });
	caso ==='pwd' &&  passwordFormRef.current.cancel();
  };

  //--------------------------------
  // Zona de renderización final

  
  const EdicionMenu=<ul>
  <li className="menuContactos" onClick={() => {setEditar(''); editarForm(0)}} style={{borderTop:'solid 1px #cfd3e099'}}>Tu cuenta de usuario</li>
  <li className="menuContactos" onClick={() => {editarForm(1)}} >Agenda de servicios</li>
  <li className="menuContactos oculto" onClick={() => {editarForm(2)}} >Página web de contacto</li>
  </ul> ;
  const fotoIco = <i className="pi pi-camera fotoIco" onClick={fotoupload} style={{position:'absolute', marginRight:'auto', top:'10%', bottom:'auto', right:'5px'}}></i>;
  const fotoCombo=<span className='fotoCombo' onClick={(e) => setCajaImg('mostrar')} style={{'display':user.Foto?'none':'inline-block', /*left:'5em',*/top:'14.8'}}>Género 
          <i className="pi pi-chevron-down" style={{float:'right',margin:'3px 0px 3px 8px'}}></i>
          </span>;
  const fotoOpcion=<div className="icofl" style={{'display': cajaImg ? 'inline-block' : 'none', top:'25.5em', right:'33em'}} onMouseLeave={() => setCajaImg('')}>
            <ul className="menufl" >
            <li><img onClick={() => {setImagen(imagenPerfil[0].value); dispatch({type:types.authLogin, payload:{...user, Genero:imagenPerfil[0].genero}}); }} alt="foto" width='70' src={fotoM}/></li>
            <li><img onClick={() => {setImagen(imagenPerfil[1].value); dispatch({type:types.authLogin, payload:{...user, Genero:imagenPerfil[1].genero}}); }} alt="foto" width='70' src={fotoF}/></li>
            </ul>
          </div>;
  const menu= <div className="layout-sidebar layout-sidebar-light" style={{width:'100%', position:'unset' }} >
    <div style={{'display': editar ?'none':'block', height:window.innerWidth>'800'?'100vh':'auto'}}>
      <span className="menuContactosH">
        <span className="textoTruncado">{'Perfil de '+user.Nombres}</span>
        <i className="pi pi-chevron-down icoCombo" ></i>
      </span>
      {EdicionMenu}
    </div>  
  </div>;
  const menuMovil = 
  		<button className="p-link layout-menu-button movil" onClick={()=>document.getElementById("menuMovil").classList.toggle('oculto')} type="button" style={{padding:'0 0.3em', fontSize:'2em', margin:'0',color:'#999', position:'relative', width:'36px', float:'right' }}>
		  <span className="pi pi-bars"/>
		</button>;
  return (
  <div id="panelEdicion" className="marco" style={{display:'flex', flexWrap:'wrap'}}>
    <Dialog className="ancho" visible={mostrar?true:false} style={{width: '50vw'}} modal onHide={() => setMostrar('')}>
      <UploadForm tipo='documentos' usu_ID={user.Usu_ID} imagen={imagen} uploadCase={'SubirFotoUsuario'} callBackOnSuccessUpload={SuccessUpload}/>

 </Dialog>
 {barras && <Barras/>}
    <div className="p-col-3 escritorio" style={{padding: '0'}}>	
    <div style={{width:'23.8vw', position:'fixed'}}>
      {menu}
    </div>  
    </div>  		
    <div className="p-col-8 negocio">
      <div className="perfil">
        <div className="layout-Seeker">
          {mando.select === 'perfil'?<Fragment> 
          <div className="p-grid p-fluid panelContacto user miniTxt">
          <h1 className="h1M" style={{width:'calc(100% - 36px)', textAlign:'center'}}>Tu cuenta de usuario</h1>{menuMovil}
            <div className="p-col-3 ancho">Foto de perfil</div>
            <div className="p-col-8 fot campo fotoPerfil"><img width='150' src={user.interno?(user.Foto?ruta+user.Foto:user.Genero ==='M'?fotoM:fotoF):user.Foto} alt="Foto de perfil"/></div>
            <div className="p-col-7 fot campo fotoPerfil reg-content-wrapper oculto" style={{background:'#fafafa',position:'relative', display:'inline-table'}}>
              <ul className="input-fields container-box">							
                <li className="cell-input">
				<div className="usu-foto" style={{float:'left', position:'relative', padding:'0'}}><img style={{width:'100%'}} src={user.Foto?ruta+user.Foto:user.Genero ==='M'?fotoM:fotoF} alt="Foto de perfil"/>{fotoIco}{fotoCombo}</div>
				<div className="usu-texto cell-body" style={{float:'right', padding:'0 0 0 1em'}}><p style={{padding:'0 0 0 2em'}}>Las dimensiones recomendadas son: 340 x 300 pixeles.</p>
				</div>
                </li>
                <li className="cell-input"><div className="cell-body" style={{clear:'right'}}>
				<label className="float-label" htmlFor="ingenero" style={{left:'2em'}}>Género</label>
				<Dropdown value={user.Genero} style={{left:'1em', maxWidth:'10em'}} options={generos} onChange={(e) => dispatch({type:types.authLogin, payload:{...user, Genero:e.value }}) } placeholder="Género" />		
                </div></li>
              </ul>	 
           </div>
            <div className="p-col-1 fot lado"><span style={{color:'#00adef',cursor:'pointer'}} onClick={() => editarCase('fot')} >Editar</span></div>
            <div className="p-col-2 fot lado oculto"><Button  className="usuBoton" label="Cancelar" onClick={() => cancelCase('fot')}/><br/><Button  className="usuBoton" label="Guardar" onClick={() => guardar('fot')}/></div>	
            <div className="p-col-3 ancho">Nombre</div>
            <div className="p-col-8 nom campo">{user.Nombres+' '+user.ApellidoPaterno+' '+user.ApellidoMaterno}</div>
            <div className="p-col-7 nom campo reg-content-wrapper oculto" style={{background:'#fafafa'}}>
              <ul className="input-fields container-box">							
                <li className="cell-input">
                  <div className="cell-body"><label className="float-label" htmlFor="innombres">Nombres</label>  
                  <InputText id="innombres"  value={user.Nombres} onChange={(e) => dispatch({type:types.authLogin, payload:{...user, Nombres:e.target.value}})} /></div>
                </li>
                <li className="cell-input">
                  <div className="cell-body" style={{width:'48%',float:'left'}}><label className="float-label" htmlFor="apelliPat">Apellido paterno</label>
                  <InputText id="apelliPat"  value={user.ApellidoPaterno} onChange={(e) => dispatch({type:types.authLogin, payload:{...user, ApellidoPaterno:e.target.value}})} /></div>
  
                  <div className="cell-body" style={{width:'48%',float:'right'}}><label className="float-label" htmlFor="apelliMat">Apellido materno</label>
                  <InputText id="apelliMat"  value={user.ApellidoMaterno} onChange={(e) => dispatch({type:types.authLogin, payload:{...user, ApellidoMaterno:e.target.value}})}/> 
                  </div>
                </li>
                <li className="cell-input">
                <input className="p-inputtext p-component" style={{visibility:'hidden'}}/>
                <p>Nota: Si tu nombre y apellido coinciden con el de tu documento de identidad, podrás tener la insignia de verificado y se te dará una mayor credibilidad, de lo contrario serás considerado con el riesgo de engaño para una transacción y podrás ser eliminado del sistema</p>
                </li>							
              </ul>	
            </div>
            <div className="p-col-1 nom lado"><span style={{color:'#00adef',cursor:'pointer'}} onClick={() => editarCase('nom')} >Editar</span></div>
            <div className="p-col-2 nom lado oculto"><Button  className="usuBoton" label="Cancelar" onClick={() => cancelCase('nom')}/><br/><Button disabled={buffer.user === user ?'disabled':''}  className="usuBoton" label="Guardar" onClick={() => guardar('nom')}/></div>	
            <div className="p-col-3 ancho">Nombre de usuario</div>
            <div className="p-col-8 usu campo">{user.Usuario}</div>
            <div className="p-col-7 usu campo reg-content-wrapper oculto" style={{background:'#fafafa'}}>
              <ul className="input-fields container-box">							
                <li className="cell-input">
                  <div className="cell-body">Tu nombre de usuario es la dirección de tu perfil en Internet</div>
                </li>
                <li className="cell-input">
                  <div className="cell-body"><label className="float-label" htmlFor="inuser">Nombre de usuario</label>
                  <InputText id="inuser" value={user.Usuario} onChange={onUsuarioChange} aria-describedby="inuser-help" /><small id="inuser-help" className="p-error p-d-block">{ buffer.user.Usuario !== user.Usuario && usuarioDisp === 'ocupado' ?  user.Usuario+' ya está registrado.':''}</small></div>
                </li>							
              </ul>
            </div>
            <div className="p-col-1 usu lado"><span style={{color:'#00adef',cursor:'pointer'}} onClick={() => editarCase('usu')} >Editar</span></div>
            <div className="p-col-2 usu lado oculto"><Button  className="usuBoton" label="Cancelar" onClick={() => cancelCase('usu')}/><br/><Button disabled={(buffer.user.Usuario === user.Usuario)||user.Usuario===''?'disabled':''} className="usuBoton" label="Guardar" onClick={() => guardar('usu')}/></div>	  
            <div className="p-col-3 ancho">Contacto</div>
            <div className="p-col-8 cto campo">{'Email: '+user.Email+', Teléfono móvil: '+user.Celular} </div>
            <div className="p-col-7 cto campo reg-content-wrapper oculto" style={{background:'#fafafa'}}>
              <ul className="input-fields container-box">							
               {/* <li className="cell-input">
                  <div className="cell-body">Correo confirmado</div>
                </li>*/}
                <li className="cell-input">
                  <div className="cell-body"> <label className="float-label" htmlFor="inemail">Correo electrónico</label>
                  <InputText id="inemail" value={user.Email} onChange={onEmailChange} aria-describedby="inemail-help" /><small id="inemail-help" className="p-error p-d-block">{ ( buffer.user.Email !== user.Email && emailDisp === 'ocupado' ?  user.Email+' ya está registrado.':'')+(!validarEmail(user.Email, false) ? ' no es un correo electrónico válido':'')}</small></div>
                </li>		
                <li className="cell-input">
                  <div className="cell-body"><label className="float-label" htmlFor="incel">Teléfono móvil</label>
                  <InputText id="incel" value={user.Celular} onChange={onCelularChange} aria-describedby="incel-help" /><small id="incel-help" className="p-error p-d-block">{ ( buffer.user.Celular !== user.Celular && celularDisp === 'ocupado' ?  user.Celular+' ya está registrado.':'')+(!validarCelular(user.Celular, false) ?  'Este número no es válido':'')}</small></div>
                </li>								
              </ul>
            </div>					
            <div className="p-col-1 cto lado"><span style={{color:'#00adef',cursor:'pointer'}} onClick={() => editarCase('cto')} >Editar</span></div>
            <div className="p-col-2 cto lado oculto"><Button  className="usuBoton" label="Cancelar" onClick={() => cancelCase('cto')}/><br/><Button disabled={buffer.user === user ?'disabled':''}  className="usuBoton" label="Guardar" onClick={() => guardar('cto')}/></div>	
            <div className="p-col-3 ancho">Ubicación</div>
            <div className="p-col-8 ubi campo">{'Distrito de '+user.Distrito+', provincia de '+user.Provincia+', departamento de '+user.Departamento+' - '+user.Pais}</div>
            <div className="p-col-7 ubi campo reg-content-wrapper oculto" style={{background:'#fafafa'}}>
			{/*
			////////////////   El componente de ubicación   ////////////////////////
			*/}

			<UbicacionForm />
            </div>	
            <div className="p-col-1 ubi lado"><span style={{color:'#00adef',cursor:'pointer'}} onClick={() => editarCase('ubi')} >Editar</span></div>
            <div className="p-col-2 ubi lado oculto"><Button  className="usuBoton" label="Cancelar" onClick={() => cancelCase('ubi')}/><br/><Button disabled={(buffer.user === user)||user.Distrito_ID==='' ?'disabled':''}  className="usuBoton" label="Guardar" onClick={() => guardar('ubi')}/></div>	
            <div className="p-col-3 ancho">Inicio de sesión</div>
            <div className="p-col-8 pwd campo">Cambiar contraseña</div>
            <div className="p-col-7 pwd campo reg-content-wrapper oculto" style={{background:'#fafafa'}}>
			{/*
			////////////////   El componente de passswoord   ////////////////////////
			*/}
			
			<PasswordForm  ref={passwordFormRef} setPassValid={setPassValid}/>
            </div>					
            <div className="p-col-1 pwd lado"><span style={{color:'#00adef',cursor:'pointer'}} onClick={() => editarCase('pwd')} >Editar</span></div>
            <div className="p-col-2 pwd lado oculto"><Button  className="usuBoton" label="Cancelar" onClick={() => cancelCase('pwd')}/><br/><Button disabled={ !passValid ?'disabled':''}  className="usuBoton" label="Guardar" onClick={() => guardar('pwd')}/></div>			

			
            <div className="p-col-3 ancho">Desactivar o eliminar</div>
            <div className="p-col-8 del campo">Desactiva tu cuenta temporalmente o elimínala definitivamente</div>
            <div className="p-col-7 del campo reg-content-wrapper oculto" style={{background:'#fafafa'}}>
              <ul className="input-fields container-box">							
                <li className="cell-input">
                  <div className="cell-body"><label className="float-label" htmlFor="inDesactiva">Desactivar cuenta (escribe: desactivar)</label>
                  <span className="p-input-icon-right"> 
                  <InputText id="inDesactiva" value={desactiva} onChange={(e) => desactivar(e.target.value)}/></span></div>
                </li>					
                <li className="cell-input">
                  <div className="cell-body"><label className="float-label" htmlFor="inElimina">Eliminar cuenta (escribe: eliminar cuenta)</label>
                  <span className="p-input-icon-right"> 
                  <InputText id="inElimina" value={elimina} onChange={(e) => eliminar(e.target.value)}/></span></div>
                </li>							
              </ul>
            </div>					
            <div className="p-col-1 del lado"><span style={{color:'#00adef',cursor:'pointer'}} onClick={() => editarCase('del')} >Editar</span></div>
            <div className="p-col-2 del lado oculto"><Button  className="usuBoton" label="Cancelar" onClick={() => cancelCase('del')}/><br/><Button  className="usuBoton" label="Continúa" disabled={desactiva==='desactivar' || elimina==='eliminar cuenta'?false:true} onClick={() => guardar('del')}/></div>			

			</div> 	
          {fotoOpcion}</Fragment> :
          mando.select === 'agenda'?
		  <div className="p-grid panelContacto user">
			  <h1 className="h1M" style={{width:'calc(100% - 36px)', textAlign:'center'}}>Agenda de servicios</h1>{menuMovil}
			  <Calendario user={user} />
		  </div>:
          mando.select === 'ficha'?
          <div className="p-grid panelContacto user">
          <h1 style={{width:'100%', textAlign:'center'}}>Información de cliente</h1>
          </div>:''}				
        </div> 	
      </div>  
    </div>  
    <div className="p-col-1 escritorio">	

    </div>  	

    <div id="menuMovil" className="menuMovil movil oculto">
      {menu}
    </div> 

  </div>
  ); 

};
